<template>
  <div id="DEPARTMENT_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Department"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`staff.field.departments`) }}</label>
        <button class="btn-action" @click="departmentSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="departments">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="departmentBadgeRemove(index)" @badgeClick="canList('DEPARTMENT') ? departmentBadgeClick(item.uuId) : ''"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <DepartmentSelectorModalForAdmin v-if="departmentSelectorShow"
      :show.sync="departmentSelectorShow"
      :preselected="departmentEditId"
      :company="company" 
      :selectorTitle="$t('department.title_selector')"
      nonAdmin
      @cancel="departmentSelectorCancel" 
      @ok="departmentSelectorOk"
    />
  </div>
</template>

<script>
import { objectClone } from '@/helpers'

export default {
  name: 'DepartmentBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    DepartmentSelectorModalForAdmin: () => import('@/components/modal/DepartmentSelectorModalForAdmin'),
    
  },
  props: {
    show: { type: Boolean, required: true }
    , departmentList: { type: Array, default: () => [] }
    , company: { type: Object, default: null }
  },
  data() {
    return {
      modalShow: false
      , departmentSelectorShow: false
      , departments: []
      , departmentEditId: null
    }
  },
  created() {
    this.modifiedList = []
  },
  beforeMount() {
    this.departments.splice(0, this.departments.length, ...this.departmentList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.departments.splice(0, this.departments.length, ...this.departmentList)
        }
      }
    }
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , departmentSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.departmentSelectorShow = false
    }
    , departmentBadgeRemove: function(index) {
      this.departments.splice(index,1)
    }
    , departmentBadgeClick(id) {
      this.departmentEditId = id
      this.departmentSelectorShow = true
    }
    , departmentSelectorToggle() {
      this.departmentEditId = null
      this.departmentSelectorShow = true
    }
    , departmentSelectorOk({ details }) {
      // this.departments.push( ...details.map(i => { return { uuId: i.uuId, name: i.name, department: i.department }}) )
      const departments = JSON.parse(JSON.stringify(this.departments));
      
      if (details.length > 0) {
        const newDepartmentsToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = departments.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const department = departments[foundIndex];
            department.uuId = r.uuId;
            department.name = r.name;
            department.department = r.department
            newDepartmentsToReplacePreselected.push(department);
            departments.splice(foundIndex, 1);
          } else {
            newDepartmentsToReplacePreselected.push({ uuId: r.uuId, name: r.name, department: r.department })
          }
        }
        
        if (this.departmentEditId != null) {
          const foundIndex = departments.findIndex(i => i.uuId == this.departmentEditId);
          if (foundIndex != -1) {
            departments.splice(foundIndex, 1, ...newDepartmentsToReplacePreselected);
          } else {
            departments.push(...newDepartmentsToReplacePreselected);
          }
        } else {
          departments.push(...newDepartmentsToReplacePreselected);
        }
      }  else if (this.departmentEditId != null) {
        //When no selection is made, remove the preselected one from the existing departments
        departments.splice(departments.findIndex(j => j.uuId === this.departmentEditId), 1);
      }
      this.departments.splice(0, this.departments.length, ...departments);

      this.departmentEditId = null;
    }
    , ok() {
      this.$emit('ok', objectClone(this.departments))
      this.$emit('update:show', false)
    }
  }
}
</script>